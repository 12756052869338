export const sliderData = [
    {
      image: "https://live.staticflickr.com/65535/53589905763_8a34229aca_k.jpg",
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53596683453_3549bce14b_k.jpg",
      heading: "Slide Two",
      desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53590027439_a0c1bd09e1_k.jpg",
      heading: "Slide Three",
      desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53589974408_88e6a88ddf_k.jpg",
      heading: "Slide Four",
      desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53611671334_7df4e45a72_k.jpg",
      heading: "Slide Five",
      desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53711402381_971f6c1ac3_k.jpg",
      heading: "Slide Six",
      desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53709027620_fb7d620525_k.jpg",
      heading: "Slide Seven",
      desc: "This is the description of slide seven Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53708807478_a6fb782e65_k.jpg",
      heading: "Slide Eight",
      desc: "This is the description of slide eight Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53708807413_9fd4b85e67_k.jpg",
      heading: "Slide Nine",
      desc: "This is the description of slide nine Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53710497602_206a10683c_k.jpg",
      heading: "Slide Ten",
      desc: "This is the description of slide ten Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53867368338_102fdbbf34_o.jpg",
      heading: "Slide Eleven",
      desc: "This is the description of slide eleven Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53867471114_14fb4c0abe_o.jpg",
      heading: "Slide Twleve",
      desc: "This is the description of slide twelve Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53756390126_b26e56585d.jpg",
      heading: "Slide Thirteen",
      desc: "This is the description of slide thirteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53756802940_f17b21b2f7.jpg",
      heading: "Slide Fourteen",
      desc: "This is the description of slide fourteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53851292887_224938eb6c_o.jpg",
      heading: "Slide Fifteen",
      desc: "This is the description of slide fifteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53867471119_48e662102c_o.jpg",
      heading: "Slide Sixteen",
      desc: "This is the description of slide sixteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53950103901_533926e04c_b.jpg",
      heading: "Slide Seventeen",
      desc: "This is the description of slide seventeen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53950430209_4298654c05_h.jpg",
      heading: "Slide Eighteeen",
      desc: "This is the description of slide eighteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53950430214_fa079ab4d7_o.jpg",
      heading: "Slide Nineteen",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54033020177_0cd254a0b9_k.jpg",
      heading: "Slide Twenty",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54034216264_18ed7b1664_k.jpg",
      heading: "Slide Twenty-One",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54034216254_b85ba3c695_k.jpg",
      heading: "Slide Twenty-Two",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
    /* {
      image: "https://live.staticflickr.com/65535/53942168511_ea88835db9_o.jpg",
      heading: "Slide Seventeen",
      desc: "This is the description of slide seventeen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942168516_d33729cab7_o.jpg",
      heading: "Slide eighteeen",
      desc: "This is the description of slide eighteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942625370_30fb043b1d_o.jpg",
      heading: "Slide Nineteen",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54033020177_0cd254a0b9_k.jpg",
      heading: "Slide Twenty",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54034216264_18ed7b1664_k.jpg",
      heading: "Slide Twentytwo",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/54034216254_b85ba3c695_k.jpg",
      heading: "Slide Nineteen",
      desc: "This is the description of slide nineteen Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942419313_c1bfea6d0b_o.jpg",
      heading: "Slide Twenty",
      desc: "This is the description of slide twenty Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942504299_1ef841365d_o.jpg",
      heading: "Slide Twenty One",
      desc: "This is the description of slide twenty one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942625355_8c7a993c0f_z.jpg",
      heading: "Slide Twenty Two",
      desc: "This is the description of slide twenty two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53942504294_b1c4cb9ded_o.jpg",
      heading: "Slide Twenty Three",
      desc: "This is the description of slide twenty three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "https://live.staticflickr.com/65535/53941267367_2285d2d506_z.jpg",
      heading: "Slide Twenty Four",
      desc: "This is the description of slide twenty four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "",
      heading: "Slide Twenty Five",
      desc: "This is the description of slide twenty five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     },
     {
      image: "",
      heading: "Slide Twenty Six",
      desc: "This is the description of slide twenty six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
     } */
  ];
